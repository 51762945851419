import "./App.css";
import Login from "./pages/login";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/register";
import { Amplify } from "aws-amplify";
import ProtectedApp from "pages/ProtectedApp";
import UpdatePassword from "pages/update-password";
import UserRequest from "pages/userRequest";
import ErrorPage from "pages/404";
import "react-toastify/dist/ReactToastify.css";
import Users from "pages/user";
import EditOfficers from "pages/user/editOfficers";
import ForgotPassword from "pages/forgot-password";
import { useSelector } from "react-redux";
import ImportOfficer from "pages/user/importOfficer";
import Settings from "pages/settings";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: "us-east-1",
    userPoolId: "us-east-1_gce6QDxlu",
    userPoolWebClientId: "1if2mvq70dhgmt66478molkasf"
  },
});

function App() {
  const authInfo = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/update_password" element={<UpdatePassword />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />

      {authInfo.role === "system_admin" && (
        <Route
          path="/user-request"
          element={
            <ProtectedApp>
              <UserRequest />
            </ProtectedApp>
          }
        />
      )}

      <Route
        path="/user"
        element={
          <ProtectedApp>
            <Users />
          </ProtectedApp>
        }
      />
      <Route
        path="/user/edit"
        element={
          <ProtectedApp>
            <EditOfficers />
          </ProtectedApp>
        }
      />
      <Route
        path="/user/import"
        element={
          <ProtectedApp>
            <ImportOfficer />
          </ProtectedApp>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedApp>
            <Settings />
          </ProtectedApp>
        }
      />
    </Routes>
  );
}

export default App;
