import { DownIcon, UpIcon } from "assets/icons/Icons";
import { Button } from "components/Button";
import React from "react";
import { useState } from "react";
import { exportToExcel, formatDate } from "utils/helper";
import ModalOfficer from "./ModalOfficer";
import Modal from "components/Modal";
import { getOffices, getOfficerResponse } from "services/officer";
import Loading from "components/Loading";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import aff_pkActions from "redux/actions/aff_pk";
import Pill from "./Pill";
import EditBox from "./EditBox";

import dayjs from "dayjs";
import Tab from "./Tab";
import ModalAddOfficerTitle from "./ModalAddOfficerTitle";
import ModalEditOfficerTitle from "./ModalEditOfficerTitle";

function InfoSubLocal({
  data,
  dataSubSelect,
  setDataSubSelect,
  dataLocalSelect,
  setDataLocalSelect,
}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const authInfo = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const [isOpenAddModalTitle, setIsOpenAddModalTitle] = useState(false);
  const openAddModalTitle = () => setIsOpenAddModalTitle(true);
  const closeAddModalTitle = () => setIsOpenAddModalTitle(false);

  const [dataEditOfficerTitle, setDataEditOfficerTitle] = useState(null);
  const [isOpenEditModalTitle, setIsOpenEditModalTitle] = useState(false);
  const openEditModalTitle = () => setIsOpenEditModalTitle(true);
  const closeEditModalTitle = () => setIsOpenEditModalTitle(false);

  const [dataOfficers, setDataOfficers] = useState([]);
  const [uuid, setUuid] = useState("");

  const [isActive, setIsActive] = useState(true);

  const handleShowDetail = () => {
    if (!show) {
      getDataOffice(data.aff_pk);
    }
    setShow(!show);
  };

  const [dataModal, setDataModal] = useState([]);
  const showModal = (data, uuid) => {
    setDataModal(data);
    setUuid(uuid);

    openModal();
  };

  const getDataOffice = async (affPk) => {
    setLoading(true);
    try {
      const office = await getOffices(affPk);

      const today = new Date();
      const priorYears = new Date(
        new Date().setFullYear(today.getFullYear() - 3)
      );

      const filterPastOfficer = office.map((item) => {
        const pastOfficers = item.past_officers.filter((pastOfficer) => {
          const expriedDate = new Date(pastOfficer.pos_expiration_dt * 1000);
          return expriedDate > priorYears;
        });
        return { ...item, past_officers: pastOfficers };
      });

      const filterActiveOffice = filterPastOfficer.filter((item) => {
        return item.is_active_office;
      });

      setDataOfficers(filterActiveOffice);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setLoading(true);
    const currentDate = dayjs(new Date()).format("MM-DD-YYYY");
    const fileName = `${data.short_name}-officers-${currentDate}`;
    try {
      const officers = await getOfficerResponse(data);
      await exportToExcel({ officers, authInfo, fileName });
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    dispatch(aff_pkActions.clearAff_pk());
    dispatch(aff_pkActions.addAff_pk(data.aff_pk));

    dispatch(aff_pkActions.clearShortName());
    dispatch(aff_pkActions.setShortName(data.short_name));

    navigate(`/user/edit`);
  };

  const handleChangeSelect = (event) => {
    if (
      dataSubSelect.some((sub) => sub.aff_pk === data.aff_pk) &&
      !event.target.checked
    ) {
      setDataSubSelect(
        dataSubSelect.filter((sub) => sub.aff_pk !== data.aff_pk)
      );
      setDataLocalSelect(
        dataLocalSelect.filter((sub) => sub.aff_pk !== data.aff_pk)
      );
      dispatch(aff_pkActions.removeAff_pk(data.aff_pk));

      dispatch(aff_pkActions.removeShortName(data.short_name));
    } else {
      setDataSubSelect([...dataSubSelect, data]);
      setDataLocalSelect([...dataLocalSelect, data]);
      dispatch(aff_pkActions.addAff_pk(data.aff_pk));

      dispatch(aff_pkActions.setShortName(data.short_name));
    }
  };

  const handleSetActive = () => {
    setIsActive(true);
  };

  const handleSetExpired = () => {
    setIsActive(false);
  };

  const handleEditOfficerTitle = (value) => {
    setDataEditOfficerTitle({ ...value, status: data?.status });
    openEditModalTitle();
  };

  return (
    <div className="px-1 pb-4 lg:px-10">
      <Loading isVisible={loading} />

      <div
        className={`${
          show && "border-green-600"
        } flex justify-between items-center border-[3px] p-2 sm:p-4 rounded-xl hover:shadow-md flex-wrap gap-2 duration-500`}
      >
        <div className="flex items-center pl-2">
          <input
            className="h-4 w-4"
            type="checkbox"
            onChange={(event) => handleChangeSelect(event)}
            checked={
              dataSubSelect.some((item) => item.aff_pk === data.aff_pk) ||
              dataLocalSelect.some((item) => item.aff_pk === data.aff_pk)
            }
          />

          <p className="text-sm sm:text-base font-bold pl-2">
            {data.short_name}
          </p>
        </div>
        {show && !dataOfficers?.length && <div>No Officer Data</div>}
        <div className="flex gap-2 items-center justify-center px-2">
          <div onClick={handleEdit}>
            <Button text="Edit" color="bg-blue-500" disabled={!isActive} />
          </div>
          <div onClick={exportExcel}>
            <Button text="Export" color="bg-green-500" disabled={!isActive} />
          </div>

          <button
            className="text-gray-700 hover:opacity-90"
            onClick={handleShowDetail}
          >
            {!show ? <DownIcon /> : <UpIcon />}
          </button>
        </div>
        {show && dataOfficers?.length > 0 && (
          <div className="border-t">
            <div className="pl-4 flex justify-between pr-4 sm:pr-10">
              <div className="pt-4">
                <Tab
                  handleSetActive={handleSetActive}
                  handleSetExpired={handleSetExpired}
                />
              </div>

              {authInfo.role === "system_admin" && (
                <div className="flex items-center" onClick={openAddModalTitle}>
                  <Button text="Create New Office" color="bg-orange-500" />
                </div>
              )}
            </div>
            <div className="w-full border p-2 mb-4 rounded-xl shadow">
              <div className="p-1 sm:p-4 text-sm md:text-base lg:flex-row flex flex-wrap flex-col">
                {dataOfficers?.map((item, index) => {
                  return (
                    <div key={index} className="pb-2.5 lg:w-1/2 font-bold">
                      <div className="sm:flex sm:gap-2 sm:items-center mb-2">
                        <div className="group relative">
                          <div className="hidden group-hover:block bg-green-500 mt-2 w-3 h-3 absolute bottom-5 left-2 rotate-45"></div>
                          <div className="hidden absolute bottom-6 bg-green-500 text-white group-hover:block group-hover:duration-1000 w-52 p-1 rounded-lg">
                            <p className="text-sm font-normal">{item?.title}</p>
                          </div>
                          <span
                            className="cursor-pointer hover:text-blue-600 duration-300"
                            onClick={() => handleEditOfficerTitle(item)}
                          >
                            {item?.aff_title}
                          </span>
                        </div>
                        {/* pill */}
                        <div className="flex gap-1 flex-wrap pb-1">
                          {item.seats && (
                            <Pill
                              text={`${
                                item.seats === 1
                                  ? `${item.seats} seat`
                                  : `${item.seats} seats`
                              }`}
                              border="border border-green-10"
                              textColor="text-green-10"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}

                          {item.is_elected && (
                            <Pill
                              text="elected"
                              border="border border-green-10"
                              textColor="text-green-10"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}
                          {item.is_executive_board && (
                            <Pill
                              text="eboard"
                              textColor="text-sky-600"
                              border="border border-sky-600"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}
                          {item.auto_executive_board && (
                            <Pill
                              text="auto-eboard"
                              textColor="text-violet-600"
                              border="border border-violet-600"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}
                          {item.is_reporting_officer && (
                            <Pill
                              text="reporting officer"
                              textColor="text-yellow-600"
                              border="border border-yellow-600"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}

                          {item?.term?.desc ? (
                            <Pill
                              text={`${
                                item.term.desc[item.term.desc.length - 1] ===
                                "s"
                                  ? item.term.desc.slice(
                                      0,
                                      item.term.desc.length - 1
                                    )
                                  : item.term.desc
                              } term`}
                              textColor="text-pink-600"
                              border="border border-pink-600/80"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          ) : (
                            <Pill
                              text={`${
                                item.term[item.term.length - 1] === "s"
                                  ? item.term.slice(0, item.term.length - 1)
                                  : item.term
                              } term`}
                              textColor="text-pink-600"
                              border="border border-pink-600/80"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}

                          {isActive && item?.officers?.length > item?.seats && (
                            <Pill
                              text="over capacity"
                              textColor="text-white"
                              bgColor="bg-red-600"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}

                          {authInfo.role === "system_admin" && (
                            <EditBox
                              text="edit"
                              textColor="text-blue-600"
                              bgColor="bg-blue-500"
                              handleEditOfficerTitle={() =>
                                handleEditOfficerTitle(item)
                              }
                            />
                          )}
                        </div>
                      </div>

                      {item[`${isActive ? "officers" : "past_officers"}`].map(
                        (el, ind) => {
                          return (
                            <div
                              className="font-normal cursor-pointer hover:text-blue-600 sm:pl-3 text-[15px] flex flex-wrap pb-2"
                              key={ind}
                              onClick={() => showModal(item, el.uuid)}
                            >
                              <span> {`${el?.first_nm} ${el?.last_nm}`} </span>
                              <span className="italic px-2">
                                {`${formatDate(el?.pos_start_dt * 1000)} - ${
                                  el?.pos_end_dt === 0 || !el?.pos_end_dt
                                    ? ""
                                    : formatDate(el?.pos_end_dt * 1000)
                                }`}
                              </span>
                              {isActive &&
                                el?.pos_expiration_dt &&
                                el?.pos_expiration_dt * 1000 < new Date() && (
                                  <div className="min-w-[135px]">
                                    <Pill
                                      text={`TERM ENDED ${formatDate(
                                        el?.pos_expiration_dt * 1000
                                      )}`}
                                      textColor="text-white"
                                      bgColor="bg-red-600"
                                    />
                                  </div>
                                )}
                            </div>
                          );
                        }
                      )}

                      {isActive && (
                        <p className="font-normal pl-3 text-sm italic">
                          {item?.officers?.length === 0 &&
                            item?.past_officers?.length > 0 &&
                            item?.seats === 1 &&
                            `vacant since ${formatDate(
                              item.past_officers[0].pos_end_dt * 1000
                            )}`}
                          {((item?.officers?.length === 0 &&
                            item?.past_officers?.length === 0) ||
                            (item?.officers?.length >= 0 &&
                              item?.seats > 1 &&
                              item?.officers?.length < item?.seats)) &&
                            `${item?.seats - item?.officers?.length} vacant ${
                              item?.seats - item?.officers?.length > 1
                                ? "seats"
                                : "seat"
                            } `}
                          {item?.officers?.length > 0 &&
                            item?.officers?.length > item?.seats &&
                            `Over capacity: ${
                              item?.officers?.length
                            } active officers, but office has only ${
                              item?.seats
                            } ${item?.seats > 1 ? "seats" : "seat"}
                          `}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      {isOpenModal && (
        <Modal title="Officer information" closeModal={closeModal}>
          <ModalOfficer
            data={dataModal}
            uuid={uuid}
            affInfo={data}
            isActive={isActive}
          />
        </Modal>
      )}

      {isOpenAddModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title=<p>
            {data.short_name}{" "}
            <span className="text-sm">{`${data.name} (${data.status})`}</span>
          </p>
          closeModal={closeAddModalTitle}
        >
          <ModalAddOfficerTitle
            data={data}
            closeModal={closeAddModalTitle}
            getDataOffice={() => getDataOffice(data.aff_pk)}
          />
        </Modal>
      )}

      {isOpenEditModalTitle && authInfo.role === "system_admin" && (
        <Modal
          title=<p>
            {data.short_name}{" "}
            <span className="text-sm">{`${data.name} (${data.status})`}</span>
          </p>
          closeModal={closeEditModalTitle}
        >
          <ModalEditOfficerTitle
            data={dataEditOfficerTitle}
            closeModal={closeEditModalTitle}
            getDataOffice={() => getDataOffice(data.aff_pk)}
          />
        </Modal>
      )}
    </div>
  );
}

export default memo(InfoSubLocal);
