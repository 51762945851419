import React, { useCallback, useEffect, useState } from "react";
import Layout from "components/layout";
import { ToastContainer } from "react-toastify";
import { Button, DisableBtn } from "components/Button";
import InfoCouncil from "./components/InfoCouncil";
import { useDispatch, useSelector } from "react-redux";
import { exportToExcel } from "utils/helper";
import Modal from "components/Modal";
import { getTreeHierarchy, getOfficerRecord } from "services/officer";
import Loading from "components/Loading";
import { notifyError } from "utils/helper";
import { useNavigate } from "react-router-dom";
import ModalTransmittal from "./components/ModalTransmittal";
import aff_pkActions from "redux/actions/aff_pk";
import chunk from "lodash.chunk";
import dayjs from "dayjs";

function Users() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const listAff_pk = useSelector((state) => state.aff_pk.listAff_pk);
  const listShort_name = useSelector((state) => state.aff_pk.listShort_name);

  const [loading, setLoading] = useState(false);
  const authInfo = useSelector((state) => state.auth);

  const [showTransmittalModal, setShowTransmittalModal] = useState(false);
  const openTransmittalModal = () => setShowTransmittalModal(true);
  const closeTransmittalModal = () => setShowTransmittalModal(false);

  const [dataCouncil, setDataCouncil] = useState([]);

  const [dataLocalSelect, setDataLocalSelect] = useState([]);
  const [allSubLocal, setAllSubLocal] = useState([]);
  const [dataSubSelect, setDataSubSelect] = useState([]);
  const [dataSubLocals, setDataSubLocals] = useState([]);

  const getDataTreeHierarchy = useCallback(async (data) => {
    setLoading(true);
    try {
      const response = await getTreeHierarchy({
        data: { affiliates: data },
      });

      const councilData = [];
      response?.data?.forEach((item) => {
        if (!item?.aff_pk) {
          item.sub_affiliates.forEach((item) => councilData.push(item));
        } else {
          councilData.push(item);
        }
      });
      setDataCouncil(
        councilData.sort((a, b) => {
          if (a.type > b.type) return 1;
          if (a.type < b.type) return -1;
          if (a.chapter > b.chapter) return 1;
          if (a.chapter < b.chapter) return -1;
          return 0;
        })
      );

      const promises = councilData.map((el) => getSubLocal(el.aff_pk));
      const result = await Promise.all(promises);
      setDataSubLocals(result);
      const allSubLocal = result.flat();
      setAllSubLocal(allSubLocal);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEdit = () => {
    if (listAff_pk.length > 0) navigate(`/user/edit`);
  };

  const handleImport = () => {
    navigate(`/user/import`);
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      const groups = chunk(listAff_pk, 5);

      const promises = groups.map(async (element) => {
        const { data } = await getOfficerRecord({
          data: { aff_pks: element },
        });
        return data;
      });
      const result = await Promise.all(promises);
      const listDataResponse = result.flat();

      const dataExcelOfficer = [];
      listDataResponse.forEach((element) => {
        if (element.aff_info?.offices) {
          // set data excel
          element.aff_info.offices.forEach((item) => {
            if (item.officers.length > 0) {
              item.officers.forEach((officer) => {
                dataExcelOfficer.push({
                  ...{ ...element, officers: [] },
                  ...officer,
                  ...officer.office,
                  chapter: element.aff_info.chapter,
                  council: element.aff_info.council,
                  local: element.aff_info.local,
                  sublocal: element.aff_info.sublocal,
                  type: element.aff_info.type,
                  state: element.aff_info.state,
                });
              });
            }
          });
        }
      });

      const currentDate = dayjs(new Date()).format("MM-DD-YYYY");
      let fileName = `${listShort_name[0]}-officers-${currentDate}`;

      if (listShort_name?.length > 1) {
        fileName = `various-officers-${currentDate}`;
      }
      exportToExcel({ officers: dataExcelOfficer, authInfo, fileName });
      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(aff_pkActions.clearAff_pk());
    dispatch(aff_pkActions.clearShortName());
  }, [dispatch]);

  useEffect(() => {
    const currentRole = authInfo?.roles?.filter(
      (role) => role.role === authInfo.role
    );
    if (currentRole) {
    getDataTreeHierarchy(currentRole[0].affiliates);
    }
  }, [authInfo.role, authInfo.roles, getDataTreeHierarchy]);

  const handleGenerateTransmittal = () => {
    openTransmittalModal();
  };

  // select all
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      dataCouncil.forEach((item) => {
        dispatch(aff_pkActions.removeAff_pk(item.aff_pk));
        dispatch(aff_pkActions.addAff_pk(item.aff_pk));

        dispatch(aff_pkActions.removeShortName(item.short_name));
        dispatch(aff_pkActions.setShortName(item.short_name));
      });

      allSubLocal.forEach((item) => {
        dispatch(aff_pkActions.removeAff_pk(item.aff_pk));
        dispatch(aff_pkActions.addAff_pk(item.aff_pk));

        dispatch(aff_pkActions.removeShortName(item.short_name));
        dispatch(aff_pkActions.setShortName(item.short_name));
      });

      setDataLocalSelect([...dataCouncil, ...allSubLocal]);
      setDataSubSelect([...allSubLocal]);
    } else {
      dataCouncil.forEach((item) => {
        dispatch(aff_pkActions.removeAff_pk(item.aff_pk));

        dispatch(aff_pkActions.removeShortName(item.short_name));
      });
      allSubLocal.forEach((item) => {
        dispatch(aff_pkActions.removeAff_pk(item.aff_pk));

        dispatch(aff_pkActions.removeShortName(item.short_name));
      });
      setDataLocalSelect([]);
      setDataSubSelect([]);
    }
  };

  const getSubLocal = async (aff_pk) => {
    try {
      const response = await getTreeHierarchy({
        data: { affiliate: aff_pk },
      });
      return response.data.sort((a, b) => {
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        if (a.chapter > b.chapter) return 1;
        if (a.chapter < b.chapter) return -1;
        return 0;
      });
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
    }
    return [];
  };

  const isSelectAll = useCallback(() => {
    const checkSelectAll =
      listAff_pk.length === dataCouncil.length + allSubLocal.length;

    return checkSelectAll;
  }, [allSubLocal, dataCouncil, listAff_pk.length]);

  return (
    <Layout>
      <Loading isVisible={loading} />
      <ToastContainer />
      <div className="container m-auto mt-4">
        <div className="w-full">
          {/* <div className="flex-1">
            <SearchInput />
          </div> */}
          <div className="flex justify-between items-start gap-2">
            <div className="flex flex-col-reverse lg:flex-row">
              {listAff_pk.length > 0 ? (
                <>
                  {authInfo.role === "system_admin" && (
                    <div
                      className="mt-2 lg:mt-0 lg:mr-2"
                      onClick={handleGenerateTransmittal}
                    >
                      <Button
                        text="Generate Transmittal File"
                        color="bg-blue-500"
                        width="w-[178px]"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {authInfo.role === "system_admin" && (
                    <div className="mt-2 lg:mt-0 lg:mr-2">
                      <DisableBtn
                        text="Generate Transmittal File"
                        width="w-[178px]"
                      />
                    </div>
                  )}
                </>
              )}
              <div onClick={handleImport}>
                <Button
                  text="Import Template File"
                  color="bg-orange-500"
                  width="w-[178px]"
                />
              </div>
            </div>
            {/* 2 */}
            <div className="flex flex-col lg:flex-row items-end">
              {listAff_pk.length > 0 ? (
                <>
                  <div className="mb-2 lg:mb-0 lg:mr-2" onClick={handleEdit}>
                    <Button
                      text="Edit selected"
                      color="bg-orange-500"
                      width="w-[125px]"
                    />
                  </div>

                  <div onClick={handleExport}>
                    <Button
                      text="Export selected"
                      color="bg-blue-500"
                      width="w-[125px]"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-2 lg:mb-0 lg:mr-2">
                    <DisableBtn text="Edit selected" width="w-[125px]" />
                  </div>
                  <div>
                    <DisableBtn text="Export selected" width="w-[125px]" />
                  </div>{" "}
                  {/* {authInfo.role === "system_admin" && (
                  <DisableBtn text="Generate transmittal file" />
                )} */}
                </>
              )}
            </div>
          </div>
        </div>

        {dataCouncil.length > 0 && (
          <div className="mt-10 flex items-center ml-4 sm:ml-6 lg:ml-10 mb-2 space-x-2 font-bold">
            <input
              type="checkbox"
              id="selectAll"
              className="h-4 w-4"
              onChange={handleSelectAll}
              checked={isSelectAll()}
            />
            <label htmlFor="selectAll">Select all</label>
          </div>
        )}

        {/* tree hireachy */}
        {dataCouncil.map((item, index) => {
          return (
            <InfoCouncil
              subLocal={dataSubLocals[index]}
              key={index}
              data={item}
              dataLocalSelect={dataLocalSelect}
              setDataLocalSelect={setDataLocalSelect}
              dataSubSelect={dataSubSelect}
              setDataSubSelect={setDataSubSelect}
            />
          );
        })}
      </div>

      {showTransmittalModal && (
        <Modal closeModal={closeTransmittalModal} title="generate transmittal">
          <ModalTransmittal closeModal={closeTransmittalModal} />
        </Modal>
      )}
    </Layout>
  );
}

export default Users;
