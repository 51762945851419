import { CheckIcon } from "assets/icons/Icons";
import { Button } from "components/Button";
import Pill from "pages/user/components/Pill";
import React from "react";
import { formatDate } from "utils/helper";

function Offices({
  dataOffices,
  handleDelete,
  handleEdit,
  handleAdd,
  handleEditOffice,
}) {
  return (
    <>
      {dataOffices.map((element, index) => (
        <div key={index} className="mb-20 flex flex-col lg:px-10 lg:pt-10 pt-5">
          <div className="flex gap-2 mb-2">
            <div className="flex flex-col md:flex-row md:items-center gap-2">
              <div className="group relative md:flex items-center gap-2">
                <div className="hidden group-hover:block bg-green-500 mt-2 w-3 h-3 absolute bottom-7 left-2 rotate-45"></div>
                <div className="hidden absolute bottom-8 bg-green-500 text-white group-hover:block group-hover:duration-1000 w-52 h-7 p-1 rounded-lg">
                  <p className="text-sm mb-2">{element?.title}</p>
                </div>
                <div className="font-bold text-lg">
                  <span
                    className="cursor-pointer"
                    onClick={() => handleEditOffice(element)}
                  >
                    {element?.type}
                    {element?.chapter} - {element?.aff_title}
                  </span>
                  <span className="text-base font-normal">
                    {element?.seats - element?.officers?.length > 0
                      ? ` (${element.seats - element.officers.length} vacant ${
                          element.seats - element.officers.length > 1
                            ? "seats)"
                            : "seat)"
                        }`
                      : ``}
                  </span>
                </div>
              </div>

              <div className="flex gap-2">
                {element?.is_elected && (
                  <Pill
                    text="elected"
                    border="border border-green-10"
                    textColor="text-green-10"
                  />
                )}
                {element?.is_executive_board && (
                  <Pill
                    text="eboard"
                    textColor="text-sky-600"
                    border="border border-sky-600"
                  />
                )}
                {element?.auto_executive_board && (
                  <Pill
                    text="auto-eboard"
                    textColor="text-violet-600"
                    border="border border-violet-600"
                  />
                )}
                {element?.is_reporting_officer && (
                  <Pill
                    text="reporting officer"
                    textColor="text-yellow-600"
                    border="border border-yellow-600"
                  />
                )}
                {element?.officers?.length > element?.seats && (
                  <Pill
                    text="over capacity"
                    textColor="text-white"
                    bgColor="bg-red-600/80"
                  />
                )}
                {element?.term?.desc ? (
                  <Pill
                    text={`${
                      element.term.desc[element.term.desc.length - 1] === "s"
                        ? element.term.desc.slice(
                            0,
                            element.term.desc.length - 1
                          )
                        : element.term.desc
                    } term`}
                    textColor="text-pink-600"
                    border="border border-pink-600/80"
                  />
                ) : (
                  <Pill
                    text={`${
                      element.term[element.term.length - 1] === "s"
                        ? element.term.slice(0, element.term.length - 1)
                        : element.term
                    } term`}
                    textColor="text-pink-600"
                    border="border border-pink-600/80"
                  />
                )}
              </div>
            </div>

            {/* add button */}
            {element.seats - element.officers.length > 0 && (
              <div className="flex mr-6 justify-end flex-1 items-center">
                <div onClick={() => handleAdd(element)}>
                  <Button text={"Fill Vacancy"} color={"bg-green-10"} />
                </div>
              </div>
            )}
          </div>
          <div
            className={`${
              element?.officers?.length === 0 && "border-t pb-2"
            } w-full overflow-x-auto`}
          >
            {element?.officers?.length > 0 && (
              <table className="shadow-md min-w-[1200px]">
                <thead className="">
                  <tr>
                    <th className="border py-4 w-44">
                      <p className="px-1">Name</p>
                    </th>
                    <th className="border py-4 w-14">
                      <p className="px-1">Active</p>
                    </th>
                    <th className="border py-4 w-32">
                      <p className="px-1">Start</p>
                    </th>
                    <th className="border py-4 w-32">
                      <p className="px-1">End</p>
                    </th>
                    <th className="border py-4 w-32">
                      <p className="px-1">Expiration</p>
                    </th>
                    <th className="border py-4 w-32">
                      <p className="px-1">Suspended</p>
                    </th>
                    <th className="border py-4 w-14">
                      <p className="px-1">Member ID</p>
                    </th>
                    {/* <th className="border py-4 w-14">
                    <p className="px-1">Temp R.O</p>
                  </th> */}
                    <th className="border py-4 w-80">
                      <p className="px-1">Address</p>
                    </th>
                    <th className="border py-4 w-32">
                      <p className="px-1">Phone</p>
                    </th>
                    <th className="border py-4 w-40">
                      <p className="px-1">Action</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {element.officers.map((item, index) => {
                    return (
                      <tr key={index} className="">
                        <td className="px-1 border text-sm text-center w-44">
                          <div> {item.first_nm + " " + item.last_nm}</div>
                        </td>
                        <td className="px-1 border text-sm w-14">
                          <div className="flex justify-center">
                            {item.active_fg && <CheckIcon />}
                          </div>
                        </td>

                        <td className="px-1 border w-32">
                          <div className="flex justify-center">
                            {!item?.pos_start_dt || item?.pos_start_dt === 0 ? (
                              <span>&mdash;</span>
                            ) : (
                              formatDate(item?.pos_start_dt * 1000)
                            )}
                          </div>
                        </td>
                        <td className="px-1 border w-32">
                          <div className="flex justify-center">
                            {!item?.pos_end_dt || item?.pos_end_dt === 0 ? (
                              <span>&mdash;</span>
                            ) : (
                              formatDate(item?.pos_end_dt * 1000)
                            )}
                          </div>
                        </td>
                        <td className="px-1 border w-32">
                          <div className="flex justify-center">
                            {!item?.pos_expiration_dt ||
                            item?.pos_expiration_dt === 0 ? (
                              <span>&mdash;</span>
                            ) : (
                              formatDate(item?.pos_expiration_dt * 1000)
                            )}
                          </div>
                        </td>
                        <td className="px-1 border w-32">
                          <div className="flex justify-center">
                            {!item?.suspended_dt || item?.suspended_dt === 0 ? (
                              <span>&mdash;</span>
                            ) : (
                              formatDate(item?.suspended_dt * 1000)
                            )}
                          </div>
                        </td>

                        <td className="px-1 border w-32">
                          <div className="flex justify-center">
                            {item?.person_pk}
                          </div>
                        </td>
                        {/* ad1,
                        ad2
                        City, state, zipcode */}
                        <td className="px-1 border text-center w-80">
                          <>
                            {item?.address.Addr1 && (
                              <>
                                {item?.address.Addr1}
                                <br />
                              </>
                            )}
                            {item?.address.Addr2 && (
                              <>
                                {item?.address.Addr2}
                                <br />
                              </>
                            )}
                            {item?.address.City}
                            {item?.address.City && item?.address.State && ", "}
                            {item?.address.State}
                            {item?.address.Zipcode &&
                              item?.address.State &&
                              ", "}
                            {item?.address.Zipcode}
                          </>
                        </td>
                        <td className="px-1 border w-32">
                          <div className="flex justify-center">
                            {item.phone || <span>&mdash;</span>}
                          </div>
                        </td>
                        <td className="px-1 border w-40">
                          <div className="flex flex-col space-y-2 p-4 items-center">
                            <div
                              onClick={() =>
                                handleEdit({
                                  aff_pk: element?.aff_pk,
                                  ...item,
                                })
                              }
                            >
                              <Button text={"Edit"} color={"bg-blue-500"} />
                            </div>
                            <div
                              onClick={() =>
                                handleDelete({
                                  aff_pk: element?.aff_pk,
                                  aff_title: `${element?.type}${element?.chapter}`,
                                  ...item,
                                })
                              }
                            >
                              <Button text={"Delete"} color={"bg-red-500"} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default Offices;
