import { Button } from "components/Button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editOfficer, getAff_pk, getOfficerRecord } from "services/officer";
import Loading from "components/Loading";
import { notifyError, formatDateISO } from "utils/helper";
import Select from "react-select";
import dayjs from "dayjs";
import { schemaEditExistOfficers } from "utils/schema";

function EditExistOfficerModal({
  dataEdit,
  closeModal,
  officers,
  setDataOfficer,
  handleLoadingFile,
}) {
  console.log("🚀 ===== dataEdit", dataEdit);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaEditExistOfficers),
  });

  const [loading, setLoading] = useState(false);
  const [dataSelect, setDataSelect] = useState([]);
  const [officerSelect, setOfficerSelect] = useState(null);

  const [errorEndDate, setErrorEndDate] = useState(false);

  const onSubmit = async (submitData) => {
    const today = new Date();
    const submitDate = new Date(submitData.pos_end_dt);

    if (submitDate < today) {
      setLoading(true);
      try {
        await editOfficer({
          data: [
            {
              uuid: officerSelect.value.uuid,
              aff_pk: officerSelect.value.aff_pk,
              pos_end_dt: dayjs(submitData.pos_end_dt).unix(),
            },
          ],
        });

        const updateErrors = officers.map((item) => {
          if (item.enterprise_ID === dataEdit.enterprise_ID) {
            const removeSeatError = item.errors.filter(
              (error) => error.message !== "No Available Seat"
            );
            return { ...item, errors: removeSeatError };
          }
          return item;
        });

        const dataTableUpdate = await handleLoadingFile(updateErrors, true);
        setDataOfficer(dataTableUpdate);

        closeModal();
      } catch (error) {
        console.log("🚀 ===== error", error);
      }
      setLoading(false);
    } else {
      setErrorEndDate(true);
    }
  };

  const getListEditOfficer = async () => {
    try {
      setLoading(true);

      const getAffPkCache = await getAff_pk({
        data: [
          {
            state: dataEdit.state,
            type: dataEdit.aff_type,
            council: +dataEdit.council,
            local: +dataEdit.local,
            subunit: dataEdit.subunit,
          },
        ],
      });

      if (getAffPkCache.data && getAffPkCache.data[0].aff_pk) {
        const aff_pk = getAffPkCache.data[0].aff_pk;
        const affInfo = await getOfficerRecord({
          data: { aff_pks: [aff_pk] },
        });
        console.log("🚀 ===== affInfo", affInfo);

        const listOffice = affInfo?.data[0].aff_info.offices;
        const filterOffice = listOffice.filter(
          (office) =>
            (office.aff_title && office.aff_title.toLowerCase() === dataEdit.officer_title.toLowerCase()) ||
            (office.title && office.title.toLowerCase() === dataEdit.officer_title.toLowerCase()) ||
            (office.afscme_title && office.afscme_title.toLowerCase() === dataEdit.officer_title.toLowerCase())
        );
        console.log("🚀 ===== filterOffice", filterOffice);

        const listOfficerEdit = filterOffice[0]?.officers.map((officer) => {
          console.log("🚀 ===== officer", officer);
          return {
            label: `${officer.first_nm} ${officer.last_nm}`,
            value: officer,
          };
        });

        setDataSelect(listOfficerEdit);
      } else {
        notifyError("no matching affiliate found");
        closeModal();
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const handleSelectOfficer = (value) => {
    console.log("🚀 ===== value", value);
    setValue("first_nm", value.value.first_nm);
    setValue("last_nm", value.value.last_nm);
    setValue("person_pk", value.value.person_pk);
    setValue("pos_end_dt", formatDateISO(value.value.pos_start_dt * 1000));
    setOfficerSelect(value);
  };

  useEffect(() => {
    getListEditOfficer();
  }, [dataEdit]);

  useEffect(() => {
    if (dataSelect.length > 0) {
      handleSelectOfficer(dataSelect[0]);
    }
  }, [dataSelect])

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Loading isVisible={loading} />

      <div className="bg-slate-200/70 p-5 sm:px-10 rounded-b-md overflow-auto max-w-2xl">
        <div className="mb-4">
          <Select
            value={officerSelect}
            options={dataSelect}
            onChange={handleSelectOfficer}
          />
        </div>
        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">First Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            disabled
            {...register("first_nm")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Last Name:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            disabled
            {...register("last_nm")}
          />
        </div>

        <div className="sm:flex pb-3">
          <p className="sm:min-w-[150px]">Member ID:</p>
          <input
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            disabled
            {...register("person_pk")}
          />
        </div>
        <div className="relative sm:flex pb-3">
          <p className="sm:min-w-[150px]">End date:</p>
          <input
            type="date"
            className="border w-full sm:w-[350px] h-8 rounded-sm text-sm outline-none px-2 focus:border-blue-400 drop-shadow-sm"
            {...register("pos_end_dt")}
          />
          {errors.pos_end_dt && (
            <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
              {errors?.pos_end_dt?.message}
            </p>
          )}
          {errorEndDate && (
            <p className="text-[11px] text-red-500 absolute right-0 -bottom-1">
              Day must be before today
            </p>
          )}
        </div>

        <div className="flex justify-end space-x-8 mt-4">
          <div onClick={closeModal}>
            <Button text={"Cancel"} color={"bg-red-500"} />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 py-1 sm:py-1.5 px-4 text-white rounded-md text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500`}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditExistOfficerModal;
