export const AFFILIATE_STATE_LIST =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/list-affiliates";

export const REQUEST_USER_ACCESS =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/new-access-request";

export const PENDING_REQUEST_LIST =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/query-access-requests";

export const RESPONSE_PENDING_REQUEST =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/dispose-access-request";

export const OFFICER_RECORD =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/query-officers";

export const TREE_HIERARCHY =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/list-affiliates";

export const EDIT_OFFICER =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/mutate-officers";

export const LIST_ADD_OFFICER =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/query-eligible-members";

export const OFFICER_TRANSMITTAL =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/query-officer-transmittal";

export const FIND_AFF_PK =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/get-aff_pk-for-affiliate";

export const EDIT_OFFICE =
  "https://bxebluni48.execute-api.us-east-1.amazonaws.com/prod/v1/mutate-offices";
